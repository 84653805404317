@import '../../styles/customMediaQueries.css';

.modalTitle {
	font-size: 24px;
	font-weight: var(--fontWeightBold);
	text-align: center;
	margin: 0 0 40px;
	
	@media (--viewportMedium) {
		font-size: 30px;
		margin: 0 0 30px;
		text-align: left;
	}
}

.shareGroup {
	@media (--viewportMedium) {
		margin: 0 0 40px;
		
		&:last-of-type {
			margin: 0 0 20px;
		}
	}
}

.hideOnMobile {
	position: absolute;
	left: -200%;
	
	@media (--viewportMedium) {
		position: relative;
		left: initial;
	}
}

.hideOnDesktop {
	@media (--viewportMedium) {
		display: none !important;
	}
}

.shareGroupTitle {
	display: none;
	
	@media (--viewportMedium) {
		display: block;
		font-size: 16px;
		font-weight: var(--fontWeightBold);
		margin: 0 0 10px;
	}
}

.copyLinkWrapper {
	height: 58px;
	position: relative;
	
	& input {
		background: rgba(231, 231, 231, .5);
		border-radius: 4px;
		border: 0;
		padding: 13px 80px 13px 20px;
		width: 100%;
    font-size: 16px;
		font-weight: var(--fontWeightRegular);
    letter-spacing: normal;
		text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
	}
}

.copyLinkButton {
	background: var(--marketplaceColor);
	border: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	transition: var(--transitionStyle);

	height: 58px;
	width: 50px;
	padding: 0;

	position: absolute;
	right: 0;
	top: 0;
	
	display: flex;
	align-items: center;
	justify-content: center;
	
	cursor: pointer;
	
	&:hover {
		background: var(--marketplaceColorLight);
	}
	
	& svg {
		fill: var(--matterColorLight);
	}
}

.copyLinkMobile {
	& .copyLinkButton {
		background-color: rgba(74, 74, 74, .15);
		border-radius: 50px;
		margin: 0 20px 40px;
		position: relative;
		width: auto;
		text-decoration: none;
		
		& svg {
			height: 18px;
			width: 18px;
			margin-right: 6px;
		}
		
		& p {
			font-size: 18px;
			padding-bottom: 2px;
		}
	}
}

.shareButtonsWrapper {
	display: grid;
	grid-template-columns: 130px 130px;
	grid-template-rows: 1fr 1fr;
	justify-content: center;
	
	@media (--viewportMedium) {
		display: flex;
		justify-content: flex-start;
	}
}

.shareButton {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90px;
	margin: 0 auto 40px;
	
	&:hover {
		text-decoration: none;
	}
	
	@media (--viewportMedium) {
		margin: 0 30px 0 0;
		width: 50px;
	}
}

.shareButtonLink {
	@media (--viewportMedium) {
		display: none;
	}
	
	& .shareButtonIcon {
		background-color: rgba(74, 74, 74, .15);
	}
	
	&:hover {
		& .shareButtonIcon {
			background-color: rgba(74, 74, 74, .25);
		}
	}
}

.shareButtonWhatsapp {
	& svg {
		@media (max-width: 767px) {
			height: 44px;
			width: 44px;
		}
	}

	& .shareButtonIcon {
		background-color: rgba(37, 211, 102, .15);
	}
	
	&:hover {
		& .shareButtonIcon {
			background-color: rgba(37, 211, 102, .25);
		}
	}
}

.shareButtonEmail {
	& svg {
		@media (max-width: 767px) {
			height: 32px;
			width: 43px;
		}
	}

	& .shareButtonIcon {
		background-color: rgba(9, 115, 251, .15);
	}
	
	&:hover {
		& .shareButtonIcon {
			background-color: rgba(9, 115, 251, .25);
		}
	}
}

.shareButtonFacebook {
	& svg {
		@media (max-width: 767px) {
			height: 44px;
			width: 44px;
		}
	}

	& .shareButtonIcon {
		background-color: rgba(66, 103, 178, .15);
	}
	
	&:hover {
		& .shareButtonIcon {
			background-color: rgba(66, 103, 178, .25);
		}
	}
}

.shareButtonTwitter {
	& svg {
		@media (max-width: 767px) {
			height: 44px;
			width: 46px;
		}
	}

	& .shareButtonIcon {
		background-color: rgba(29, 161, 242, .15);
	}
	
	&:hover {
		& .shareButtonIcon {
			background-color: rgba(29, 161, 242, .25);
		}
	}
}

.shareButtonIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	
	margin: 0 auto 15px;
	height: 90px;
	width: 90px;
	
	border-radius: 100%;
	transition: var(--transitionStyle);
	
	@media (--viewportMedium) {
		margin: 0 auto 10px;
		height: 50px;
		width: 50px;
	}
}

.shareButtonText {
	color: var(--matterColor);
	font-size: 20px;
	font-weight: var(--fontWeightRegular);
	line-height: 1;
	margin: 0;
	
	@media (--viewportMedium) {
		font-size: 14px;
	}
}

.notificationContainer {
  position: fixed;
  z-index: 101;
	left: 0;
	right: 0;
  top: 87px;
}

.notificationSubContainer {
  display: flex;
  justify-content: center;
}

.notificationSubContainer > div {
  max-width: 400px;
}

.successAlert {
	border-radius: 10px;
	background-color: var(--colorSuccess);
	color: var(--colorWhite);
	font-size: 16px;
	padding: 10px 20px;
}